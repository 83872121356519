<template>
    <van-popup
        v-model="visible"
        class="component_popup_m"
        :style="{
            'width': width,
            paddingTop: paddingTop
        }"
        round
        closeable
        @click-close-icon="() => $emit('close')"
        :position="position"
        :close-on-click-overlay="modelClose">

        <div v-if="isSlot" class="component_popup_content pop_common_slot">
            <p class="title_top">{{ title }}</p>
            <slot></slot>
        </div>

        <div v-else class="flex-column-center popup_common">
            <p class="title">{{ title }}</p>
            <p class="msg" :class="msgAlign" v-html="msg"></p>
            <el-button @click="$emit('confirm')" class="btn" v-show="btn">{{ btn }}</el-button>
            <p class="tips" v-if="tips">{{ tips }}</p>
        </div>
    </van-popup>
</template>

<script>
import { isMobile } from '@/utils/utils';
import { Button } from 'element-ui';
import { Overlay, Popup } from 'vant';
import { mapGetters } from 'vuex';

export default {
    components: {
        [Popup.name]: Popup,
        [Button.name]: Button,
        [Overlay.name]: Overlay
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
          default: isMobile() ? '90%' : '600px',
        },
        title: {
            type: String,
            default: '',
        },
        msg: {
            type: String,
            default: '',
        },
        btn: {
            type: String,
            default: '',
        },
        msgAlign: {
            type: String,
            default: 'left'
        },
        tips: {
            type: String,
            default: null
        },
        modelClose: {
            type: Boolean,
            default: false
        },
        position: {
            type: String,
            default: 'center'
        }
    },
    computed: {
      ...mapGetters(['isMobile']),
        isSlot() {
            return !!(this.$slots.default && this.$slots.default.length > 0)
        },
        visible: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
        paddingTop() {
            if(this.isMobile) {
                if(this.position === 'bottom') {
                    return '10px'
                }else {
                    return '40px'
                }
            }else {
                return '20px'
            }
        }
    },
    watch: {
        visible(val) {
            val && this.$emit('init')
        }
    }
}
</script>

<style lang="scss">
.component_popup_m {
    padding: 20px;
    .msg {
        &.left {
            text-align: left;
        }
        &.center {
            text-align: center;
        }
        &.right {
            text-align: right;
        }
    }
    .el-dialog__body {
        padding: 30px 15px 30px 20px;
    }
    .el-dialog__headerbtn {
        font-size: 28px;
    }
    .component_popup_content {
        max-height: 1000px;
        overflow-y: auto;
        padding-right: 5px;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background: gray;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-track {
            background: #ccc;
        }
    }
}

</style>
